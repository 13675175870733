<template>
    <div v-if="loading == false">
        <ChirpReport
            :data_source="rows"
            :export_report="export_report"
            :target_page="targetPage"
            :loadingColumns="loadingTable"
            :column_meta="columnMeta"
            :totalCount="totalCount"
            :storeKey="storeKey"
            :useOverride="useOverride"
            @cancelOverride="useOverride = false"
        />
    </div>
</template>
<script>
    import { tryGetFilter } from '@/util/tryGetFilter';

    const api_root = 'reports';
    const report = 'client_income';
    import ChirpReport from '@/components/general/list/ChirpReport';
    export default {
        name: 'ClientIncome',
        components: { ChirpReport },
        props: {
            overrideFilter: {
                type: Object,
                default: null,
            },
        },
        data() {
            return {
                rows: [],
                columnMeta: [
                    { field: 'client', headerText: 'Client', allowSorting: true },
                    { field: 'appt_count', headerText: 'Number of Appointments', allowSorting: true },
                    { field: 'client_insurance', headerText: 'Total Client Insurance', allowSorting: false },
                    { field: 'client_income', headerText: 'Total Client Payment', allowSorting: true },
                    { field: 'client_percentage', headerText: 'Percentage of Total Income', allowSorting: true },
                ],
                totalCount: 0,
                targetPage: '',
                loading: true,
                useOverride: false,
                loadingTable: false,
                criteriaProps: {},
                export_report: 'client_income'
            };
        },
        computed: {
            criteria() {
                return this.useOverride && this.overrideFilter ? this.overrideCriteria : this.savedCriteria;
            },
            savedCriteria() {
                return tryGetFilter(this.$store, this.storeKey);
            },
            overrideCriteria() {
                return this.$store.getters['filters/overrideCriteria'](this.storeKey);
            },
            storeKey() {
                return this.pageMeta?.page || 'client-income';
            },
        },
        methods: {
            getPercentage(numerator, denominator) {
                const options = {
                    style: 'percent',
                    maximumFractionDigits: 2,
                };

                if (!numerator || !denominator) {
                    return Number(0).toLocaleString('en-US', options);
                }

                return Number(numerator / denominator).toLocaleString('en-US', options);
            },
            async load_data(criteria = this.criteria) {
                const body = {
                    criteria: {
                        ...criteria,
                        report,
                    },
                };
                const res = await this.$api.post(`/${api_root}`, body);
                this.rows = res.data.rows.map((row) => ({
                    client: row.client || '--',
                    appt_count: row.appt_count || 0,
                    client_insurance: '--',
                    client_income: row.client_income ? this.$getCurrency(row.client_income) : this.$getCurrency(0),
                    client_percentage: this.getPercentage(row.client_income, row.total_income),
                }));

                this.totalCount = res.data.total_count;

                this.$nextTick(() => {
                    this.loading = false;
                });
            },
            async init() {
                if (this.overrideFilter) {
                    this.useOverride = true;
                    this.$store.commit('filters/applyOverride', {
                        stateKey: this.storeKey,
                        criteria: this.overrideFilter,
                    });
                }

                await this.load_data();
            },
            async modifyCriteria(criteria) {
                if (this.targetPage) {
                    this.$store.commit('filters/update', {
                        criteriaPage: this.targetPage,
                        payload: criteria,
                    });
                }

                await this.load_data();
            },
        },
        async created() {
            await this.init();
        },
        watch: {
            criteria: {
                deep: true,
                async handler(newVal, oldVal) {
                    let updatedNewVal = { ...newVal };

                    if (Object.keys(newVal.sort).length === 0){
                        updatedNewVal.sort = oldVal.sort;
                    }

                    const newStr = JSON.stringify(updatedNewVal);
                    const oldStr = JSON.stringify(oldVal);
                    if (newStr !== oldStr) {
                        await this.load_data(updatedNewVal);
                    }
                },
            },
        },
    };
</script>
